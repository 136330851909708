import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { alwaysEmptyObject } from 'ramda-extension';
import { Message } from '@myci/intl';
import { logOut } from '@myci/authentication';
import { Button } from '@ci/atoms';
import { prepareStyle } from '@ci/styles';

import messages from '../messages';

const signoutButtonStyle = prepareStyle(utils => ({
	color: 'black',
	paddingBottom: utils.spacings.sm,
	paddingInlineEnd: utils.spacings.md,
	paddingInlineStart: utils.spacings.md,
	paddingTop: utils.spacings.sm,
	selectors: {
		':hover': {
			color: 'black',
		},
	},
}));

const SignoutButton = ({ logOut, ...rest }) => (
	<Button
		customStyle={signoutButtonStyle}
		className="main-nav__sign-out"
		onClick={logOut}
		variant="subtle"
		{...rest}
	>
		<Message {...messages.signout} />
	</Button>
);

SignoutButton.propTypes = {
	logOut: PropTypes.func.isRequired,
};

export default connect(alwaysEmptyObject, { logOut: () => logOut() })(SignoutButton);
