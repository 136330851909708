import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'ramda';
import { cx } from 'ramda-extension';
import { Icon } from '@myci/ui-components';

import withConnectedLocale from '../decorators/withConnectedLocale';

const NavigationMobileLanguageSwitcher = ({
	isLoading,
	loadLocale,
	locale,
	isLanguageSwitcherOpen,
	onLanguageSwitcherOpen,
	supportedLocales,
}) => (
	<ul className="main-nav__list">
		{!isLoading && (
			<li>
				<a
					className={cx('main-nav__link main-nav__link--bordered', {
						open: isLanguageSwitcherOpen,
					})}
					href="javascript:;"
					onClick={() => onLanguageSwitcherOpen(!isLanguageSwitcherOpen)}
				>
					<Icon type="language" />
					<span className="main-nav__link-text">{locale}</span>
				</a>
				<div className="sub-nav-mobile">
					<ul className="sub-nav-mobile__list">
						<li>
							{supportedLocales &&
								map(
									languageCode => (
										<a
											key={languageCode}
											className="sub-nav__link"
											href="javascript:;"
											onClick={() => {
												loadLocale(languageCode, { userRequested: true });
											}}
										>
											{languageCode}
										</a>
									),
									supportedLocales
								)}
						</li>
					</ul>
				</div>
			</li>
		)}
	</ul>
);

NavigationMobileLanguageSwitcher.propTypes = {
	isLanguageSwitcherOpen: PropTypes.bool,
	isLoading: PropTypes.bool,
	loadLocale: PropTypes.func,
	locale: PropTypes.string,
	onLanguageSwitcherOpen: PropTypes.func,
	supportedLocales: PropTypes.array,
};

export default withConnectedLocale(NavigationMobileLanguageSwitcher);
