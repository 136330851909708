import React from 'react';
import { ModuleIndex } from '@myci/module-index';
import { isInBrowser } from '@myci/utils';

import Logo from '../components/Logo';
import { appNavigation, customNavigation } from '../navigation';
import { moduleConfig } from '../config';
import { AuthorizedLayout } from '../layouts';

const App = () => {
	if (isInBrowser()) {
		return (
			<AuthorizedLayout navigation={{ appNavigation, customNavigation }} renderLogo={Logo}>
				<ModuleIndex config={moduleConfig} />
			</AuthorizedLayout>
		);
	}

	return <AuthorizedLayout navigation={{ appNavigation }} />;
};

export default App;
