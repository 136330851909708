import { navigationModules as nav } from '@myci/module-index';

export const appNavigation = [
	{
		...nav.boDashboard,
		sublinks: [nav.userManagement, nav.adminManagement, nav.stats],
	},
	{
		...nav.boProducts,
	},
	{
		...nav.boMessages,
	},
	{
		...nav.logs,
		sublinks: [
			nav.authLogs,
			nav.atomicLogs,
			nav.paymentLogs,
			nav.notificationLogs,
			nav.receiptLogs,
			nav.usageLogs,
		],
	},
	{
		...nav.boHelp,
		sublinks: [],
	},
];

export const customNavigation = [];
