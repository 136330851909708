import { LookupProvider, definitions, lookupData } from '@myci/domain-lookups';
import { fetchScoring, getScore, scoringMiddleware, scoringReducer } from '@myci/domain-report';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ClientLink, Icon, Spinner, TemplatePage, ThemeProvider } from '@myci/ui-components';
import { IntlProvider, Message, isRtl as getIsRtl, isLoadingLocale } from '@myci/intl';
import { Toasts } from '@ci/toasts';
import { apiErrorToastMiddleware } from '@myci/api';
import {
	fetchPlanAccessConfig,
	fetchReportCounter,
	selectPlanAccessConfig,
	selectReportCounterConfig,
} from '@myci/domain-plan-management';
import { Secure, selectIsLoggingOut } from '@myci/authentication';
import { ChoosePaymentMethodModal } from '@myci/domain-subscriptions';
import { compose, filter, isNil, map, path } from 'ramda';
import { isNilOrEmpty, toCamelCase } from 'ramda-extension';
import { useDispatch, useSelector } from 'react-redux';
import { withMiddleware, withReducers } from 'redux-syringe';
import {
	InstanceTypes,
	currentInstanceType,
	getCurrentInstanceEnv,
	getEnvironmentVariable,
} from '@myci/utils';
import {
	CheckUnauthorizedUser,
	CheckUserPassword,
	backOfficeUserManager,
	fetchBoAccountData,
	fetchWalletBalance,
	getAccountStatus,
	getWalletBalanceAmount,
	selectIsFetchingUserAccount,
	selectIsPasswordChangeNecessary,
	selectIsUserAccountInitialized,
	useAllPermissions,
	useAnyPermissions,
	userWalletMiddleware,
	userWalletReducer,
} from '@myci/user-settings';
import { StylesProvider } from '@ci/styles';
import { storeLookupData } from '@ci/lookups';
import { InstanceConfigContext } from '@myci/instances';
import { initialize, pageview } from 'react-ga';

import { BACK_OFFICE_HOMEPAGE, FRONT_OFFICE_HOMEPAGE, applicationTypes } from '../constants';
import BackButton from '../components/BackButton';
import Breadcrumbs from '../containers/Breadcrumbs';
import PageFooter from '../containers/Footer';
import NavigationLanguageSwitcher from '../containers/NavigationLanguageSwitcher';
import NavigationUserMenu from '../containers/NavigationUserMenu';
import messages from '../messages';
import { Header, defaultNavigationItems, otherMessageElements } from '../components';
import { useTouchTracking } from '../utils';
import { renderer } from '../renderer';
import { theme } from '../theme';
import { moduleConfig } from '../config';
import { LayoutContextProvider } from '../features/layout';

const mapModulesToTranslations = map(link => ({
	...link,
	messageElement: link.messageElement || <Message {...messages[toCamelCase(link.slug)]} />,
	sublinks: link.sublinks.map(sublink => ({
		...sublink,
		messageElement: sublink.messageElement || <Message {...messages[toCamelCase(sublink.slug)]} />,
	})),
}));

const instance = getEnvironmentVariable('GATSBY_MYCI_INSTANCE');

const getTitle = path(['siteMetadata', 'title']);

const applicationSpinner = (
	<Spinner>
		<Message {...messages.loadingApplication} />
	</Spinner>
);

const moduleSpinner = (
	<Spinner>
		<Message {...messages.loadingModule} />
	</Spinner>
);

const getNavigationLogoLink = applicationType => {
	switch (applicationType) {
		case applicationTypes.frontOffice: {
			return FRONT_OFFICE_HOMEPAGE;
		}
		case applicationTypes.backOffice: {
			return BACK_OFFICE_HOMEPAGE;
		}
		default:
			return 'app';
	}
};

const AuthorizedLayout = ({ applicationType, navigation, site, title, ...otherProps }) => {
	const dispatch = useDispatch();
	const { settings } = moduleConfig;
	const isRtl = useSelector(getIsRtl);
	const accountStatus = useSelector(getAccountStatus);
	const loadingLocale = useSelector(isLoadingLocale);
	const score = useSelector(getScore);
	const walletBalance = useSelector(getWalletBalanceAmount);
	const isUserAccountInitialized = useSelector(selectIsUserAccountInitialized);
	const isPasswordChangeNecessary = useSelector(selectIsPasswordChangeNecessary);
	const reportCounterConfig = useSelector(selectReportCounterConfig);
	const planAccessConfig = useSelector(selectPlanAccessConfig);
	const isFetchingUserAccount = useSelector(selectIsFetchingUserAccount);
	const hasAnyPermissions = useAnyPermissions();
	const hasAllPermissions = useAllPermissions();
	const boUserData = useSelector(backOfficeUserManager.selectFirst);
	const isLoggingOut = useSelector(selectIsLoggingOut);
	const isBoPasswordChangeRequired = boUserData?.passwordChangeRequired;
	const intl = useIntl();
	const accountUnauthorized = accountStatus === 'Unauthorized';
	const isFoInstance = currentInstanceType === InstanceTypes.INSTANCE_FO;
	const shouldFetchUserData =
		isFoInstance &&
		isUserAccountInitialized &&
		!isPasswordChangeNecessary &&
		!accountUnauthorized &&
		!isLoggingOut;

	const { googleAnalyticsId, googleAnalyticsTurnedOn } = getCurrentInstanceEnv();

	useEffect(() => {
		if (googleAnalyticsTurnedOn && googleAnalyticsId) {
			initialize(googleAnalyticsId);
			pageview(window.location.pathname + window.location.search);
		}
	}, [googleAnalyticsTurnedOn, googleAnalyticsId]);

	useEffect(() => {
		if (!isFoInstance) {
			dispatch(fetchBoAccountData());
		}
	}, []);

	useEffect(() => {
		if (shouldFetchUserData && isNil(reportCounterConfig)) {
			dispatch(fetchReportCounter());
		}
	}, [reportCounterConfig, shouldFetchUserData]);

	useEffect(() => {
		if (shouldFetchUserData && isNil(planAccessConfig)) {
			dispatch(fetchPlanAccessConfig());
		}
	}, [planAccessConfig, shouldFetchUserData]);

	useEffect(() => {
		if (shouldFetchUserData && isNil(score)) {
			dispatch(fetchScoring());
		}
	}, [score, shouldFetchUserData]);

	useEffect(() => {
		if (shouldFetchUserData && isNil(walletBalance) && settings?.isWalletEnabled) {
			dispatch(fetchWalletBalance());
		}
	}, [walletBalance, shouldFetchUserData]);

	useEffect(() => {
		dispatch(storeLookupData(lookupData));
	}, []);

	useTouchTracking();

	const getNavigationModules = map(({ sublinks = [], ...rest }) => ({
		sublinks: filter(({ permissions }) => {
			if (isNilOrEmpty(permissions)) {
				return true;
			}

			const { any: anyPermissions, all: allPermissions } = permissions;

			return hasAnyPermissions(anyPermissions) && hasAllPermissions(allPermissions);
		})(sublinks),
		...rest,
	}));

	const navigationModules = mapModulesToTranslations(
		getNavigationModules(navigation.appNavigation)
	);

	// TODO: The display of this element should probably be configurable per instance.
	const isSearchVisible = false;
	const renderSearch = useCallback(
		() =>
			isSearchVisible ? (
				<ClientLink
					className="main-nav__link"
					to={defaultNavigationItems.navigationSearchItem.slug}
				>
					<Icon type="search" />
					<span className="main-nav__link-text">{otherMessageElements.search}</span>
				</ClientLink>
			) : null,
		[isSearchVisible]
	);

	if ((isFetchingUserAccount && !isUserAccountInitialized) || loadingLocale) {
		return applicationSpinner;
	}

	return (
		<StylesProvider direction={isRtl ? 'rtl' : 'ltr'} theme={theme} renderer={renderer}>
			<ThemeProvider theme={{ isRTL: isRtl }}>
				<LookupProvider definitions={definitions} renderIcon={Icon} dynamicMessageProp="value">
					<InstanceConfigContext.Provider value={settings}>
						<LayoutContextProvider navigationModules={navigationModules}>
							<Secure>
								<Fragment>
									<CheckUnauthorizedUser
										accountUnauthorized={accountUnauthorized}
										isPasswordChangeNecessary={
											isFoInstance ? isPasswordChangeNecessary : isBoPasswordChangeRequired
										}
									>
										<CheckUserPassword
											isPasswordChangeNecessary={
												isFoInstance ? isPasswordChangeNecessary : isBoPasswordChangeRequired
											}
										>
											<TemplatePage
												instance={instance}
												renderBackButton={BackButton}
												renderHeader={Header}
												navigationLogoLink={getNavigationLogoLink(applicationType)}
												navigationModules={!accountUnauthorized ? navigationModules : []}
												renderSearch={renderSearch}
												renderUserMenu={NavigationUserMenu}
												renderNavigationLanguageSwitcher={NavigationLanguageSwitcher}
												renderPageFooter={PageFooter}
												renderBreadcrumbs={Breadcrumbs}
												innerFallback={moduleSpinner}
												rootFallback={applicationSpinner}
												title={title ? intl.formatMessage(title) : getTitle(site)}
												customNavigation={!accountUnauthorized ? navigation.customNavigation : []}
												{...otherProps}
											/>
										</CheckUserPassword>
									</CheckUnauthorizedUser>
									<ChoosePaymentMethodModal />
								</Fragment>
							</Secure>
							<Toasts />
						</LayoutContextProvider>
					</InstanceConfigContext.Provider>
				</LookupProvider>
			</ThemeProvider>
		</StylesProvider>
	);
};

AuthorizedLayout.propTypes = {
	applicationType: PropTypes.string.isRequired,
	children: PropTypes.node,
	navigation: PropTypes.shape({
		appNavigation: PropTypes.arrayOf(PropTypes.object).isRequired,
		customNavigation: PropTypes.arrayOf(PropTypes.object),
	}),
	site: PropTypes.shape({
		siteMetadata: PropTypes.shape({
			title: PropTypes.string,
			author: PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }),
		}),
	}),
	title: PropTypes.object,
};

const AuthorizedMemoizedLayout = memo(AuthorizedLayout);

const AuthorizedLayoutWithIntl = props => (
	<IntlProvider>
		<AuthorizedMemoizedLayout {...props} />
	</IntlProvider>
);

export default compose(
	withReducers({ scoring: scoringReducer, userWallet: userWalletReducer }, { isGlobal: true }),
	withMiddleware(
		{ apiErrorToastMiddleware, scoringMiddleware, userWalletMiddleware },
		{ isGlobal: true }
	)
)(AuthorizedLayoutWithIntl);
