import React, { memo, useContext } from 'react';
import { Router } from '@gatsbyjs/reach-router';
import { isInBrowser } from '@myci/utils';

import { LayoutContext } from '../features/layout';

const Breadcrumbs = () => {
	const { breadcrumbs } = useContext(LayoutContext);

	if (isInBrowser()) {
		return breadcrumbs ? <Router primary={false}>{breadcrumbs}</Router> : null;
	}

	return null;
};

export default memo(Breadcrumbs);
