import React, { memo, useContext } from 'react';
import { Router } from '@gatsbyjs/reach-router';
import { isInBrowser } from '@myci/utils';

import { LayoutContext } from '../features/layout';

const Footer = () => {
	const { footer } = useContext(LayoutContext);

	if (isInBrowser()) {
		return footer ? <Router primary={false}>{footer}</Router> : null;
	}

	return null;
};

export default memo(Footer);
