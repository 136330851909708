import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ThemeAuthorizedLayout from 'gatsby-theme-myci/src/layouts/AuthorizedLayout';
import { applicationTypes } from 'gatsby-theme-myci';

const AuthorizedLayout = props => (
	<StaticQuery
		query={graphql`
			{
				site {
					buildTime
					siteMetadata {
						title
						author {
							url
							name
						}
					}
				}
			}
		`}
		render={staticQueryProps => (
			<ThemeAuthorizedLayout
				{...staticQueryProps}
				{...props}
				applicationType={applicationTypes.backOffice}
			/>
		)}
	/>
);
export default AuthorizedLayout;
