import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@myci/ui-components';
import { connect } from 'react-redux';
import { Message } from '@myci/intl';
import { getUser } from '@myci/user-settings';
import { cx } from 'ramda-extension';
import { applySpec } from 'ramda';
import { navigate } from '@gatsbyjs/reach-router';

import messages from '../messages';

import SignoutButton from './SignoutButton';

const NavigationUserMenu = ({ isUserMenuOpen, onUserMenuOpen, onNavigationXSOpen, user }) => (
	<ul className="main-nav__list position-relative">
		<li>
			<a
				className={cx('main-nav__link main-nav__link--bordered main-nav__user', {
					open: isUserMenuOpen,
				})}
				href="javascript:;"
				onClick={() => onUserMenuOpen(!isUserMenuOpen)}
			>
				<Icon type="user" />
				<span className="main-nav__link-text">{user}</span>
			</a>
			<div className="sub-nav-mobile">
				<ul className="sub-nav-mobile__list">
					<li>
						<a
							className="sub-nav__link"
							href="javascript:;"
							onClick={() => {
								navigate('/app/my-profile');
								onNavigationXSOpen(false);
								onUserMenuOpen(false);
							}}
						>
							<Message {...messages.myProfile} />
						</a>
					</li>
				</ul>
			</div>
		</li>
		<SignoutButton />
	</ul>
);

NavigationUserMenu.propTypes = {
	isUserMenuOpen: PropTypes.bool,
	onNavigationXSOpen: PropTypes.func,
	onUserMenuOpen: PropTypes.func,
	user: PropTypes.string,
};

export default connect(
	applySpec({
		user: getUser,
	})
)(NavigationUserMenu);
